/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
 
        $( "#impressum" ).hide();

        // parallax, baby!
        /*
        $('#parallax-plane').backstretch([
          '/app/uploads/2015/07/papa.jpg'
        ], {centeredY: false});
        */
        $('#parallax-plane').backstretch([
          '/app/themes/susanneriedl/dist/images/susanneriedl-header.jpg'
        ], {centeredY: false});

        // external links
        $('a[href$=".pdf"]').click(function(){open(this.href);return false;});
        $('a[href^="http"]:not(.lightbox)').newWin();

        // imprint
        $( ".btn-impressum" ).click(function() {
          $( "#impressum" ).slideDown( "fast", function() {

          $('html, body').animate({
            scrollTop: $("#impressum").offset().top
          }, 1200, function () {
            window.location.hash = "impressum";
          });

          });
        });

        // soft scrolling
        var $root = $('html, body');
        $('a:not(.carousel-control):not(.badge)').click(function() {
          var href = $.attr(this, 'href');
          $root.animate({
            scrollTop: $(href).offset().top
          }, 1200, function () {
            window.location.hash = href;
          });
        return false;
        });

        // touch support via jQuery Mobile
        $("#erklaerungen-slider").swiperight(function() {
          $(this).carousel('prev');
        });
        $("#erklaerungen-slider").swipeleft(function() {
          $(this).carousel('next');
        });

        // pop over
        $('[data-toggle="popover"]').popover();

        // tool tip
        $('#dot-nav li').each(function() {
          var $this = $(this);
          var $title =  $this.find('a').text();
          $this.attr('data-toggle', 'tooltip').attr('data-container','body').attr('data-placement', 'left').attr('title', $title );
        });
        $('[data-toggle="tooltip"]').tooltip();

        // carousel control
        $('.badge-xs').each(function() {
          var $this = $(this);
          var $slide = $this.text();
          $this.click(function() {
            // alert($slide);
            $('#erklaerungen-slider').carousel($slide - 1);
          });
        });


/*
        // ScrollMagic
        // 
        // https://ihatetomatoes.net/wp-content/uploads/2015/05/GreenSock-CheatsheetV1-1.pdf
        var controller = new ScrollMagic.Controller();
        var scene = new ScrollMagic.Scene({
                        triggerElement: "#menu-navigation",
                        triggerHook: "onLeave",
                        offset: -42,
                        duration: 42
                      })
                      .setTween("#dot-nav", 0.25, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

       var scene2 = new ScrollMagic.Scene({
                        triggerElement: "#p1",
                        triggerHook: "onLeave",
                        offset: 0,
                        duration: 100
                      })
                      .setTween(".vegas-slide", 0.25, { top: '100px' }) // trigger a TweenMax.to tween
                      .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);
*/


     /*   var scene = new ScrollMagic.Scene({
          triggerElement: "#sue",
          duration: $(window).height() - 100,
          triggerHook: 0,
          reverse: true
        })
        .setPin("#sue")
        .addTo(controller);
      */
        // WOW – animation settings
        // new WOW().init();
        // https://github.com/matthieua/WOW

        // animations using velocity.js
        // http://julian.com/research/velocity/



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


        // ScrollMagic
        // 
        // https://ihatetomatoes.net/wp-content/uploads/2015/05/GreenSock-CheatsheetV1-1.pdf
        var controller = new ScrollMagic.Controller();
        var scene = new ScrollMagic.Scene({
                        triggerElement: "#menu-navigation",
                        triggerHook: "onLeave",
                        offset: -42,
                        duration: 42
                      })
                      .setTween("#dot-nav", 0.25, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

       var scene0 = new ScrollMagic.Scene({
                        triggerElement: "#intro",
                        triggerHook: "onEnter",
                        offset: 0,
                        duration: "200%"
                      })
                      .setTween("#parallax-plane", 0.25, { top: "40%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
      //                .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

        var scene_krankenhaus = new ScrollMagic.Scene({
                        triggerElement: "#krankenhaus",
                        triggerHook: 0.5,
                        offset: "28%",
                        duration: "21%"
                      })
                      .setTween("#krankenhaus-plan-menschen", 0.25, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

        var scene_krankenhaus_punkte = new ScrollMagic.Scene({
                        triggerElement: "#krankenhaus",
                        triggerHook: 0.5,
                        offset: "42%",
                        duration: "28%"
                      })
                      .setTween(".badge", 0.5, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

        var scene_ipad = new ScrollMagic.Scene({
                        triggerElement: "#ipad",
                        triggerHook: 0.7,
                        offset: 0,
                        duration: "42%"
                      })
                      .setTween("#ipad-on", 0.5, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

/*
       var scene1 = new ScrollMagic.Scene({
                        triggerElement: "#p1",
                        triggerHook: "onEnter",
                        offset: 0,
                        duration: "150%"
                      })
                      .setTween("#p2", 0.25, { top: "20%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
                      .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);
            */

/*       var scene2 = new ScrollMagic.Scene({
                        triggerElement: "#p1",
                        triggerHook: "onLeave",
                        offset: 0,
                        duration: "50%"
                      })
                      .setTween("#p2", 0.25, { top: "20%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
                      .addIndicators({name: "2 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);                      
*/
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
